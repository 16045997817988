import React from 'react';

function Services() {
  return (
    <section id="services" className="services">
      <h2>Our Services</h2>
      <div className="service-grid">
        <div className="service-item">
          <h3>OTC Trading</h3>
          <p>Execute large trades with minimal market impact</p>
        </div>
        <div className="service-item">
          <h3>Liquidity Provision</h3>
          <p>Deep liquidity across major cryptocurrencies</p>
        </div>
        <div className="service-item">
          <h3>Global Payments</h3>
          <p>Receive and send cross border payments for a fraction of the cost</p>
        </div>
        <div className="service-item">
          <h3>Risk Management</h3>
          <p>Tailored solutions to mitigate market volatility</p>
        </div>
        <div className="service-item">
          <h3>24/7 Support</h3>
          <p>Round-the-clock assistance for our clients</p>
        </div>
      </div>

    </section>
  );
}

export default Services;