import React from 'react';
import mainLogo from '../images/main-logo.png';

function Header() {
  return (
    <header className="App-header">
      <nav>
        <div className="logo">
          <span><strong>D1</strong>technologies</span>
        </div>
        <ul>
          <li><a href="#home">Home</a></li>
          <li><a href="#about">About Us</a></li>
          <li><a href="#services">Our Services</a></li>         
        </ul>
      </nav>
    </header>
  );
}

export default Header;