import React from 'react';
import { FaLinkedin } from 'react-icons/fa';

function Footer() {
  return (
    <footer>
      <div className="footer-top">
        <div className="footer-links">
          <a href="https://docs.google.com/document/d/1J8QHry83kX1F3rWAw7K0phD0jvnodgHE8RvjyhLSlo0/edit" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
          <span className="separator">|</span>
          <a href="https://docs.google.com/document/d/1xHoo6LL8W76_Tz2g4OTo8aHz0sI6TqgJH6YSi1DEjTg/edit" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
        </div>
        <div className="social-icons">
          <a href="https://www.linkedin.com/company/d1-technologies" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
          <a href="https://x.com/d1techinc" target="_blank" rel="noopener noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
              <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
            </svg>
          </a>
        </div>
      </div>
      <div className="footer-middle">
        <div className="footer-info">
          <p>D1 Technologies Incorporated is incorporated in the state of Florida and is registered as a money service business within the United States.</p>
          <p>FinCEN Registration: 31000278244870</p>
          <p>Florida Money Services Businesses Part II: FT230000456</p>
        </div>
        <div className="footer-disclaimer">
          <p>The material provided on this website is provided for information purposes only and does not constitute an offer or solicitation for the purchase of any crypto assets or any form of financial instruments referencing crypto assets.</p>
          <p>The information on this website is not directed at nor intended for distribution to, or use by, any person resident in any country or jurisdiction where such distribution or use would be contrary to local law or regulation.</p>
        </div>
      </div>
      <div className="footer-separator"></div>
      <div className="footer-bottom">
        <p>2525 Ponce De Leon Blvd, Suite 300, Coral Gables FL 33134</p>
        <p>&copy; {new Date().getFullYear()} D1 Technologies Incorporated. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;