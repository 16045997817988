import React from 'react';
import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import OurPartners from './components/OurPartners';
import PartnerLogos from './components/PartnerLogos';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <Hero />
        <AboutUs />
        <Services />
        <OurPartners />
        <PartnerLogos />
      </main>
      <Footer />
    </div>
  );
}

export default App;
