import React from 'react';
import sumsub from '../images/sumsub.png';
import chainalysis from '../images/chainanalysis.png';
import leadbank from '../images/leadbank.png';
import fireblocks from '../images/fireblocks.png';
import coincover from '../images/coincover.png';

function PartnerLogos() {
  const logos = [sumsub, chainalysis, leadbank, fireblocks, coincover];
  
  return (
    <div className="partner-logos">
      <div className="ticker-wrap">
        <div className="ticker">
          {[...logos, ...logos, ...logos].map((logo, index) => (
            <div key={index} className="ticker-item">
              <img src={logo} alt={`Partner logo ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PartnerLogos;