import React from 'react';

function AboutUs() {
  return (
    <section id="about" className="about-us">
      <div className="container">
        <h2>About Us</h2>
        <p>
          Our diverse team has deep roots in cross-border business and a focus on emerging countries. 
          With extensive experience in big tech, consulting and international banking, D1 is uniquely 
          positioned to tackle the ever-changing regulatory and technology landscape to help solve all 
          of your liquidity and global payment needs.
        </p>
      </div>

    </section>
  );
}

export default AboutUs;